<template>
  <!-- 包车订单 -->
  <div ref="charterOrder" class="charterOrder">
    <div ref="functionButtons" class="function-buttons"></div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="queryForm"
        class="demo-form-inline"
        label-position="left"
        label-width="85px"
      >
        <el-form-item label="订单编号：">
          <el-input
            v-model="queryForm.orderId"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input
            v-model="queryForm.passengerPhone"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单状态：">
          <el-select v-model="queryForm.orderStatus" placeholder="请选择">
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车商名称：">
          <el-select
            v-model="queryForm.carServiceProviderId"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in carDealerList"
              :key="item.id"
              :label="item.name"
              :value="item.companyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单时间：">
          <el-date-picker
            v-model="queryForm.startTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="支付时间：">
          <el-date-picker
            v-model="queryForm.paymentDate"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="退款时间：">
          <el-date-picker
            v-model="queryForm.refundDate"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
          <el-button
            v-if="listFind('导出')"
            type="primary"
            size="small"
            @click="exportFun"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :operation="true"
      :table-height="tableHeight"
    >
      <template slot-scope="scope">
        <div>
          <el-button
            type="text"
            size="small"
            sort="primary"
            @click="charterDispatchPopupFun(scope.scopeRow, '详情')"
            >详情</el-button
          >
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="queryForm.pageSize"
        :current-page="queryForm.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <charterDispatchPopup ref="charterDispatchPopupRef"></charterDispatchPopup>
  </div>
</template>

<script>
import {
  getHistoryOrderPageListAPI,
  getHistoryOrderPageListExportAPI,
  carPackageRenderAPI,
  charteredOrderDetailAPI,
} from "@/api/lib/api.js";
import charterDispatchPopup from "../scheduling/charterDispatchModel/charterDispatchPopup.vue";
export default {
  components: {
    charterDispatchPopup,
  },
  data() {
    return {
      tableData: [],
      titleName: [
        {
          title: "联系人",
          props: "passengerName",
          // width: 220,
        },
        {
          title: "联系手机",
          props: "passengerPhone",
          width: 120,
        },
        {
          title: "出发地",
          props: "startPoint",
          render: (h, params, index) => {
            return h("span", params.startPoint.name);
          },
          // width: 120,
        },
        {
          title: "目的地",
          props: "endPoint",
          render: (h, params, index) => {
            return h("span", params.endPoint?.name || "-");
          },
          // width: 120,
        },
        {
          title: "出发时间",
          props: "startAt",
          width: 160,
        },
        {
          title: "用车需求",
          props: "vehicleDemand",
          SpecialJudgment: (res) => {
            return res || "-";
          },
          // width: 150,
        },
        {
          title: "订单总额",
          props: "totalPrices",
          // width: 140,
        },
        {
          title: "车商名称",
          props: "carServiceProviderName",
          // width: 110,
        },
        {
          title: "车辆&驾驶员",
          width: 160,
          SpecialJudgment: (res) => {
            if (res.driverName1 || res.driverName2) {
              if (res.driverName2) {
                return `${res.vehiclePlateNumber}（${res.driverName1}）；${res.vehiclePlateNumber}（${res.driverName2}）`;
              } else {
                return `${res.vehiclePlateNumber}（${res.driverName1}）`;
              }
            } else {
              return "-";
            }
          },
        },
        {
          title: "订单状态",
          props: "orderStatusCode",
          SpecialJudgment: (res) => {
            return res == 5
              ? "待报价"
              : res == 10
              ? "待支付"
              : res == 20
              ? "待派车"
              : res == 30 || res == 40 || res == 50
              ? "服务中"
              : res == 70
              ? "已完成"
              : res == 95
              ? "退款中"
              : res == 90 || res == 100
              ? "已退款"
              : res == 110
              ? "已取消"
              : "";
          },
          // width: 160,
        },
        {
          title: "支付时间",
          props: "wxTransactionTime",
          width: 160,
        },
        {
          title: "退款时间",
          props: "refundAt",
          width: 160,
        },
        {
          title: "退款金额",
          props: "refundOrderPrices",
        },
      ],
      total: 0,
      params: {},
      queryForm: {
        orderId: null,
        passengerPhone: null,
        orderStatus: 0,
        carServiceProviderId: null,
        startTime: [],
        paymentDate: [],
        refundDate: [],
        pageSize: 10,
        currentPage: 1,
      },
      orderStatusList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 5,
          label: "待报价",
        },
        {
          value: 10,
          label: "待支付",
        },
        {
          value: 20,
          label: "待派车",
        },
        {
          value: 4,
          label: "服务中",
        },
        {
          value: 70,
          label: "已完成",
        },
        {
          value: 6,
          label: "已退款",
        },
        {
          value: 110,
          label: "已取消",
        },
      ],
      packageList: [],
      carDealerList: [],
      tableHeight: 0,
    };
  },
  mounted() {
    // 时间计算，默认一个月
    let startTime = this.getLastMonthTime(new Date());
    let day = new Date();
    let endTime =
      day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate();
    this.queryForm.startTime = [startTime, endTime];
    this.renderData();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
    // 获取包车套餐下拉数据
    carPackageRenderAPI().then((res) => {
      if (res.code == "SUCCESS") {
        console.log(res.data.name);
        this.packageList = res.data;
      }
    });
    // 获取车商下拉数据
    this.providerListFun().then((res) => {
      this.carDealerList = res;
    });
  },
  methods: {
    async charterDispatchPopupFun(scopeRow, type) {
      let data = scopeRow;
      if (type === "详情") {
        const res = await charteredOrderDetailAPI({ orderId: scopeRow.id });
        if (res.code === "SUCCESS") {
          data = Object.assign(data, res.data);
        }
      }
      this.$refs.charterDispatchPopupRef.open(data, type);
    },
    // 点击查询按钮的时候
    queryFun() {
      this.queryForm.currentPage = 1;
      this.renderData();
    },
    // 导出方法
    exportFun() {
      let obj = this.deepClone(this.params);
      getHistoryOrderPageListExportAPI(obj).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "包车订单 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.charterOrder.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        36;
    },
    // 表格数据渲染
    renderData() {
      this.params = {
        orderId: this.queryForm.orderId,
        passengerPhone: this.queryForm.passengerPhone,
        orderStatus:
          this.queryForm.orderStatus == 0
            ? []
            : this.queryForm.orderStatus == 4
            ? [30, 40, 50, 95]
            : this.queryForm.orderStatus == 6
            ? [90, 100]
            : [this.queryForm.orderStatus],
            companyId: this.queryForm.carServiceProviderId,
        startTime:
          this.queryForm.startTime && this.queryForm.startTime[0]
            ? this.queryForm.startTime[0] + " 00:00:00"
            : null,
        endTime:
          this.queryForm.startTime && this.queryForm.startTime[1]
            ? this.queryForm.startTime[1] + " 23:59:59"
            : null,
        refundStartDate:
          this.queryForm.refundDate && this.queryForm.refundDate[0]
            ? this.queryForm.refundDate[0] + " 00:00:00"
            : null,
        refundEndDate:
          this.queryForm.refundDate && this.queryForm.refundDate[1]
            ? this.queryForm.refundDate[1] + " 23:59:59"
            : null,
        paymentStartDate:
          this.queryForm.paymentDate && this.queryForm.paymentDate[0]
            ? this.queryForm.paymentDate[0] + " 00:00:00"
            : null,
        paymentEndDate:
          this.queryForm.paymentDate && this.queryForm.paymentDate[1]
            ? this.queryForm.paymentDate[1] + " 23:59:59"
            : null,
        pageSize: this.queryForm.pageSize,
        currentPage: this.queryForm.currentPage,
      };
      getHistoryOrderPageListAPI(this.params).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.queryForm.currentPage = val;
      this.renderData();
    },
    // 分页change方法
    onSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.currentPage = 1;
      this.renderData();
    },
    // 获取上一个月时间,返回yyyy-MM-dd字符串
    getLastMonthTime(date) {
      //  1    2    3    4    5    6    7    8    9   10    11   12月
      var daysInMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      var strYear = date.getFullYear();
      var strDay = date.getDate();
      var strMonth = date.getMonth() + 1;
      //一、解决闰年平年的二月份天数   //平年28天、闰年29天//能被4整除且不能被100整除的为闰年,或能被100整除且能被400整除
      if ((strYear % 4 === 0 && strYear % 100 !== 0) || strYear % 400 === 0) {
        daysInMonth[2] = 29;
      }
      if (strMonth - 1 === 0) {
        //二、解决跨年问题
        strYear -= 1;
        strMonth = 12;
      } else {
        strMonth -= 1;
      }
      //  strDay = daysInMonth[strMonth] >= strDay ? strDay : daysInMonth[strMonth];
      strDay = Math.min(strDay, daysInMonth[strMonth]); //三、前一个月日期不一定和今天同一号，例如3.31的前一个月日期是2.28；9.30前一个月日期是8.30

      if (strMonth < 10) {
        //给个位数的月、日补零
        strMonth = "0" + strMonth;
      }
      if (strDay < 10) {
        strDay = "0" + strDay;
      }
      var datastr = strYear + "-" + strMonth + "-" + strDay;
      return datastr;
    },
  },
};
</script>

<style scoped lang="scss">
.charterOrder {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
  ::v-deep .form-area .el-form .el-form-item .el-input__inner {
    width: 15vw !important;
  }
  ::v-deep .el-range-separator {
    line-height: 26px;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner,
  .el-input__inner {
    width: 15vw !important;
  }
}
.Table {
  margin-top: 16px;
}
</style>
